<!-- Item wrapper -->
<div
    class="fuse-vertical-navigation-item-wrapper"
    [class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
    [ngClass]="item.classes?.wrapper"
>
    <div class="fuse-vertical-navigation-item">
        <!-- Icon -->
        <mat-icon
            class="fuse-vertical-navigation-item-icon"
            [ngClass]="item.classes?.icon"
            *ngIf="item.icon"
            [svgIcon]="item.icon"
        ></mat-icon>

        <!-- Title & Subtitle -->
        <div class="fuse-vertical-navigation-item-title-wrapper">
            <div class="fuse-vertical-navigation-item-title">
                <span [ngClass]="item.classes?.title">
                    {{ item.title }}
                </span>
            </div>
            <div
                class="fuse-vertical-navigation-item-subtitle"
                *ngIf="item.subtitle"
            >
                <span [ngClass]="item.classes?.subtitle">
                    {{ item.subtitle }}
                </span>
            </div>
        </div>

        <!-- Badge -->
        <div class="fuse-vertical-navigation-item-badge" *ngIf="item.badge">
            <div
                class="fuse-vertical-navigation-item-badge-content"
                [ngClass]="item.badge.classes"
            >
                {{ item.badge.title }}
            </div>
        </div>
    </div>
</div>

<ng-container *ngFor="let item of item.children; trackBy: trackByFn">
    <!-- Skip the hidden items -->
    <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Basic -->
        <fuse-vertical-navigation-basic-item
            *ngIf="item.type === 'basic'"
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-basic-item>

        <!-- Collapsable -->
        <fuse-vertical-navigation-collapsable-item
            *ngIf="item.type === 'collapsable'"
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"
        ></fuse-vertical-navigation-collapsable-item>

        <!-- Divider -->
        <fuse-vertical-navigation-divider-item
            *ngIf="item.type === 'divider'"
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-divider-item>

        <!-- Group -->
        <fuse-vertical-navigation-group-item
            *ngIf="item.type === 'group'"
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-group-item>

        <!-- Spacer -->
        <fuse-vertical-navigation-spacer-item
            *ngIf="item.type === 'spacer'"
            [item]="item"
            [name]="name"
        ></fuse-vertical-navigation-spacer-item>
    </ng-container>
</ng-container>
